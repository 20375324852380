
html {
    font-size: 100%;
    height: 100%;
}
body {
    background-color: $sidebar-color;
    color: $base-font-color;
    font-family: $base-font-family;
    font-size: $base-font-size;
    padding: 0px !important;
    margin: 0px !important;
    line-height: 1.5;
    font-style: normal;
    font-weight: normal;
    height: 100%;
}
body{
    &.condense-menu{
        .header-seperation{
            display:none;
        }
        .page-content{
            margin-left:50px;
        }
        .footer-widget{
            display: none;
        }
        .scrollup{
            left:34px;
        }
    }
    &.hide-top-content-header{
        .header{
            width:auto;
        }
        .header-quick-nav{
            display:none;
        }
        .page-content .content{
            padding-top:25px;
        }
        .page-sidebar.mini{
            .page-sidebar-wrapper{
                >ul{
                    padding: 0;
                }
            }
        }
    }
    &.hide-sidebar{
        .header-seperation{
            display:none;
        }
        .page-sidebar{
            display:none;
        }
        .page-content{
            margin-left:0;
        }
       .scrollup{
             left:34px;
        }
    }
    &.boxed-layout{
        background-color: $base-light-five;
        .header{
            width:inherit;
            padding-right:30px !important;
        }
    }
    &.menu-non-fixed{
        .page-sidebar{
            position: absolute;
        }
    }
    &.right-side-bar{
        .page-content{
            margin-right: 250px;
            margin-left: 0;
            &.condensed{
                margin-left: 0;
                margin-right:$sidebar-condensed-width;
            }
        }
        .page-sidebar{
            right: 0;
        }
        .header {
            .header-seperation{
                float:right;
            }
        }
        .footer-widget{
            right: 0;
        }
    }
    &.bottom-header{
        .header{
            bottom: 0;
        }
        .page-content {
            .content{
                padding-top: 25px;
                padding-bottom: 83px;
            }
        }
    }
    &.open-menu-right-desktop{
        .page-container{
            position: relative;
            left: -260px;
        }
        .header{
            left: -260px;
        }
        .fonter-widget{
            display: none;
        }
    }
    &.modal-open{
        .page-container{
             position: inherit;
        }
        .page-content{
            position: inherit;
        }
        .chat-window-wrapper{
            display: none;
        }
    }

}
.navbar, .navbar-inverse {
    background-color: transparent;
    background-image: none;
    border: none;
    border-radius: 0px;
}
.navbar {
    .nav {
        & > li {
            & > .dropdown-menu {
                &::before {
                    position: absolute;
                    top: -7px;
                    right:9px;
                    display: inline-block;
                    border-right: 7px solid transparent;
                    border-bottom: 7px solid #CCC;
                    border-left: 7px solid transparent;
                    border-bottom-color: rgba(0, 0, 0, 0.2);
                    content: '';
                }
                &::after {
                    position: absolute;
                    top: -6px;
                    right:10px;
                    display: inline-block;
                    border-right: 6px solid transparent;
                    border-bottom: 6px solid white;
                    border-left: 6px solid transparent;
                    content: '';
                }
            }
        }
    }
    .notifications {
        background-color: #1B1E24;
        display: block;
        float:left;
        left: 0;
        margin: 0 10px 0 0;
        padding-top: 5px;
        position: relative;
    }
}
.header-seperation {
    background-color: #58585a;
}
.header {
    -webkit-transition: -webkit-transform 0.3s ease,left  0.3s ease, right  0.3s ease;
    transition: transform 0.3s ease,left  0.3s ease, right  0.3s ease;
    padding: 0 !important;
    margin: 0 !important;
    position: fixed;
    width: 100%;
    z-index: 999;
    min-height: 60px;
    left:0;
    -webkit-backface-visibility:hidden;
    .navbar-inner {
        background-image: none !important;
        background-color: $color-white !important;
        width: 100%;
        margin: 0;
        border: 0px;
        padding: 0px;
        box-shadow: none;
        border-radius: 0px;
        display: table;
        height: 60px;
        [class*="span"] {
            min-height: 58px !important;
        }
    }
    .btn-navbar {
        background-color: #1f1f1f !important;
        margin-bottom: 0px;
        padding-right: 0px;
        padding-top: 10px;
        padding-bottom: 6px;
        background-image: none;
        box-shadow: none;
        color:  $color-white ;
        border: 0;
        &:hover {
            text-decoration: none;
        }
    }
    .nav {
        display: block;
        li {
            &.dropdown {
                .dropdown-toggle {
                    i {
                        color: #eff4f8 !important;
                    }
                }
                &.user {
                    .dropdown-toggle {
                        i {
                            display: inline-block;
                            margin-top: 5px;
                            margin: 0;
                            font-size: 16px;
                            color: #808080 !important;
                        }
                    }
                }
            }
        }
        & > li {
            margin: 0px;
            padding: 0px;
            text-align: center;
            display: inline-block;
            float:left;
            &.dropdown {
                &:last-child {
                }
                .dropdown-toggle {
                    margin: 0px;
                    i {
                        font-size: 15px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    .badge {
                        position: absolute;
                        font-size: 10px !important;
                        font-weight: 700;
                        top: 0px;
                        right: 2px;
                        text-align: center;
                        background-color: $color-danger;
                        padding: 4px 6px;
                        -webkit-border-radius: 12px !important;
                        -moz-border-radius: 12px !important;
                        border-radius: 12px !important;
                        text-shadow: none !important;
                        color:  $color-white ;
                    }
                }
                .active {
                    background-color: $sidebar-header-iconset-background-active;
                }
                &.user {
                    .dropdown-toggle {
                        padding: 8px 4px 7px 9px;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            &.quicklinks {
                i {
                    display: inline-block;
                    margin-top: 5px;
                    margin: 0;
                    font-size: 14px;
                    color: #656565 !important;
                }
                & > a {
                    padding: 4px 2px !important;
                }
            }
        }
        .h-seperate {
            height: 6px;
            width: 2px;
            margin: 0px 10px 0px 10px;
            border-left: 1px solid #e1e1e1;
            display: inline;
            position: relative;
            top: 2px;
        }
        .dropdown-menu {
            margin-top: 3px;
        }
    }
    .header-seperation {
        width: 250px;
        display: block;
        float:left;
        height: 60px;
        .notifcation-center {
            margin-right:15px !important;
        }
    }
    .header-quick-nav {
        padding-right:10px;
        -webkit-transition-duration: 1;
        -moz-transition-duration: 1s;
        transition-duration: 1s;
        transition-timing-function: ease-in-out;
        -webkit-transition-property: -webkit-transform, opacity;
        -moz-transition-property: -moz-transform, opacity;
        transition-property: transform, opacity;
        input {
            height: 12px;
        }
    }
    .notifcation-center {
        background-color: $sidebar-header-iconset-background;
        margin-top: 12px;
        margin-right:15px !important;
        display: inline-flex;
        display: -ms-flexbox;
        direction: ltr;
        li {
            min-width: 30px !important;
            a {
                padding: 10px !important;
                min-height: 17.5px;
                i {
                    font-size: 13px !important;
                }
            }
        }
    }
    a {
        text-decoration: none;
    }
    .chat-toggler {
        position: relative;
        top: 10px;
        margin-right:5px;
        display: inline-block;
        float:left;
        .user-details {
            display: inline-block;
            float:left;
            line-height: 35px;
            .username {
                a {
                    color: #1b1e24;
                    font-size: 15px;
                }
            }
            .company {
                a {
                    color: #8b8f92;
                    font-weight: 400;
                    text-align: right;
                    font-size: 12px;
                }
            }
        }
        .iconset {
            display: inline-block;
            float:left;
            margin: 15px 10px;
        }
        .profile-pic {
            display: inline-block;
            border-radius: 100px 100px 100px 100px;
            display: inline-block;
            height: 35px;
            overflow: hidden;
            width: 35px;
            float:left;
        }
        a {
            color: #1b1e24;
            font-size: 15px;
            display: inline-block;
            float:left;
        }
        & > i {
            color: #808080;
            text-align: right;
            font-size: 20px;
            position: relative;
            padding: 8px;
            top: 8px;
        }
        .message-count {
            display: inline-block;
            float:left;
            .badge {
                position: absolute;
                float:left;
                right: 0;
                top: 0;
            }
        }
    }
    .chat-message-preview {
        border-left: 1px solid #e5e9ec;
        border-right: 1px solid #e5e9ec;
        padding: 4px 15px;
        margin-right:12px;
        .user-details {
            display: inline-block;
            float:left;
            .username {
                a {
                    color: #22262e;
                    font-weight: 600;
                    text-align: right;
                }
            }
        }
        .profile-pic {
            display: inline-block;
            border-radius: 100px 100px 100px 100px;
            display: inline-block;
            float:left;
            height: 35px;
            overflow: hidden;
            width: 35px;
            margin-left:10px;
        }
        a {
            color: #6F7B8A;
        }
        .chat-message {
            color: #8b8f92;
            background-color: #e5e9ec;
            padding: 5px;
            font-size: 12px;
            border-radius: 3px;
        }
    }
    .quick-section {
        margin-left:20px;
        margin-top: 16px;
        margin-right:10px;
        display: inline-block;
        float:left;
        direction: ltr;
    }
    .search-form {
        display: inline-block;
        float:left;
        margin-left:25px !important;
        top: -4.5px;
        position: relative;
    }
    .popover-title {
        border-bottom: 0px;
        padding-top: 14px;
    }
}
.header .nav .dropdown-toggle:hover,
.header .nav .dropdown.open .dropdown-toggle {
}
.menu-title {
    color: #808285;
    font-size: 10.5px;
    padding-left:31px;
    padding-right:31px;
    padding-top: 25px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.2px;
    a {
        color: #808285;
        &:hover {
            text-decoration: none;
        }
    }
    i {
        font-size: 10px;
        font-weight: normal;
    }
}
.page-sidebar {
    background-color: $sidebar-color !important;
    height: 100%;
    font-family: $base-font-family-two;
    -webkit-backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    .outer-tab-nav-section {
        display: inline-block;
        width: 45px;
        position: absolute;
        height: 100%;
        background-color: $color-primary !important;
    }
    .inner-menu {
        display: inline-block;
        width: 200px;
        ul{
            width: 200px;
        }
    }
    .page-sidebar-wrapper {
        position: relative;
        overflow-y: auto;
        & > ul {
            list-style: none;
            margin: 0;
            padding: 0;
            margin: 0;
            padding: 0;
            & > li {
                display: block;
                margin: 0;
                padding: 0;
                border: 0px;
                & > a {
                    color: $sidebar-link-color;
                    display: block;
                    position: relative;
                    margin: 0;
                    border: 0px;
                    padding: 11.5px 31px 11.5px 31px;
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: normal;
                    &.open {
                        background: #313131;
                    }
                    i {
                        font-size: 14px;
                        margin-right:5px;
                        text-shadow: none;
                    }
                    & > .arrow {
                        &.open {
                            &:before {
                                float:right;
                                margin-top: 1px;
                                margin-right:3px;
                                display: inline;
                                font-family: FontAwesome;
                                height: auto;
                                font-size: 16px;
                                content: "\f107";
                                font-weight: 300;
                                text-shadow: none;
                            }
                        }
                    }
                }
                &:last-child {
                    & > a {
                        border-bottom: 1px solid transparent !important;
                        border-bottom: 1px solid transparent !important;
                    }
                }
                a {
                    i {
                        color: $sidebar-link-color;
                        width: 20px;
                        text-align: center;
                        display: inline-block;
                    }
                }
                &.active {
                    & > a {
                        border-top-color: transparent !important;
                        color:  $color-white ;
                        border: none;
                        text-shadow: none;
                        i {
                        color:  $color-white ;
                        }
                    }
                    i {
                        color:  $color-white ;
                    }
                    & > ul {
                        &.sub-menu {
                            display: block;
                            & > li {
                                ul {
                                    &.sub-menu {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
                & > ul {
                    &.sub-menu {
                        display: none;
                        list-style: none;
                        clear: both;
                        margin: 0px;
                        background-color: $sidebar-inner-ul-list-background;
                        padding: 8px 0px 10px 0px;
                        & > li {
                            background: none;
                            margin: 0px;
                            padding: 0px;
                            margin-top: 1px !important;
                            & > a {
                                margin-left:0px;
                                padding-left:5px;
                                display: block;
                                margin: 0px 0px 0px 0px;
                                padding: 5px 0px;
                                padding-left:53px !important;
                                color: #e1eaf1;
                                text-decoration: none;
                                text-shadow: 0 1px 1px #000;
                                font-size: 13px;
                                font-weight: 300;
                                background: none;
                                &:hover {
                                    background: $sidebar-inner-link-hover !important;
                                }
                            }
                            &:first-child {
                                & > a {
                                    border-top: 0px !important;
                                }
                            }
                            ul {
                                &.sub-menu {
                                    display: none;
                                    list-style: none;
                                    clear: both;
                                    margin: 0px 0px 0px 0px;
                                    padding-left:0;
                                    li {
                                        background: none;
                                        margin: 0px;
                                        padding: 0px;
                                    }
                                }
                            }
                            & > ul {
                                &.sub-menu {
                                    & > li {
                                        & > a {
                                            margin-left:70px;
                                        }
                                        & > ul {
                                            &.sub-menu {
                                                & > li {
                                                    & > a {
                                                        padding-left:80px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        li {
                            & > a {
                                & > .arrow {
                                    &:before {
                                        float:right;
                                        margin-top: 1px;
                                        margin-right:20px;
                                        display: inline;
                                        font-size: 16px;
                                        font-family: FontAwesome;
                                        height: auto;
                                        content: "\f104";
                                        font-weight: 300;
                                        text-shadow: none;
                                    }
                                    &.open {
                                        &:before {
                                            float:right;
                                            margin-top: 1px;
                                            margin-right:18px;
                                            display: inline;
                                            font-family: FontAwesome;
                                            height: auto;
                                            font-size: 16px;
                                            content: "\f107";
                                            font-weight: 300;
                                            text-shadow: none;
                                        }
                                    }
                                }
                            }
                            & > ul {
                                &.sub-menu {
                                    & > li {
                                        & > a {
                                            display: block;
                                            padding: 5px 0px;
                                            color: #ccc;
                                            text-decoration: none;
                                            text-shadow: 0 1px 1px #000;
                                            font-size: 13px;
                                            font-weight: 300;
                                            & > i {
                                                font-size: 13px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.start {
                    & > a {
                        border-top-color: transparent !important;
                    }
                }
            }
        }
    }
    &.mini {
        width: 50px;
        overflow: visible !important;
        background-color: $color-primary !important;
        height: 100%;
        margin-top: 0;
        position: fixed;
        z-index: 90;
        .page-sidebar-wrapper {
            & > ul {
                width: 50px;
                padding: 60px 0 0;
                & > li {
                    & > ul {
                        &.sub-menu {
                            position: absolute;
                            margin-top: -46px;
                            margin-left:50px;
                            width: auto;
                            z-index: 110;
                            min-width: 250px;
                        }
                    }
                    & > a {
                        padding: 13px;
                        color:  $color-white ;
                    }
                    a {
                        i {
                            color:  $color-white ;
                        }
                    }
                }
            }
            & > p {
                display: none;
            }
            & > .side-bar-widgets {
                display: none;
            }
        }
        .slimScrollDiv {
            overflow: visible !important;
            .page-sidebar-wrapper {
                overflow: visible !important;
            }
        }
        .user-info {
            display: none;
        }
        .status-widget {
            display: none;
        }
        .notification-alert {
            display: none;
        }
        ul {
            li {
                span {
                    &.title {
                        display: none;
                    }
                    &.arrow {
                        display: none;
                    }
                    &.badge {
                        display: none;
                    }
                }
                ul {
                    li {
                        span {
                            &.title {
                                display: inline;
                            }
                            &.arrow {
                                display: inline;
                            }
                        }
                    }
                }
            }
        }
        ul{
            &.big-items{
                span {
                    &.badge {
                        display: inline-block;
                    }
                }
            }
           &.small-items{
                span {
                    &.badge {
                        display: inline-block;
                    }
                }
            }
        }
        .profile-wrapper {
            display: none;
        }
        .footer-widget {
            display: none;
        }
    }
    ul {
        &.folders {
            list-style: none;
            margin: 0px;
            padding: 0;
            & > li {
                border: 0 none;
                display: block;
                margin: 0;
                padding: 0;
                & > a {
                    padding: 5px 31px;
                    font-size: 13px;
                    color:  $color-white ;
                    display: block;
                    position: relative;
                    &:hover {
                        text-decoration: none;
                    }
                }
                & > input {
                    margin: 5px 31px;
                    font-size: 13px;
                    width: 150px;
                }
            }
        }
        & > li {
            & > a {
                & > .arrow {
                    &:before {
                        float:right;
                        margin-top: 1px;
                        margin-right:5px;
                        display: inline;
                        font-size: 16px;
                        font-family: FontAwesome;
                        height: auto;
                        content: "\f104";
                        font-weight: 300;
                        text-shadow: none;
                    }
                }
            }
        }
    }
}
.nav-collapse {
    &.collapse {
        height: 100%  !important;
    }
}
.page-sidebar  .page-sidebar-wrapper > ul > li.open > a,
.page-sidebar  .page-sidebar-wrapper > ul > li >a:hover,
.page-sidebar  .page-sidebar-wrapper > ul > li:hover >a {
    background: $sidebar-link-hover;
    color:  $color-white ;
}
.page-sidebar.mini  .page-sidebar-wrapper > ul > li a > .label{
    display: none;
}

.page-sidebar.mini .page-sidebar-wrapper > ul > li.open > a,
.page-sidebar.mini .page-sidebar-wrapper > ul > li > a:hover,
.page-sidebar.mini .page-sidebar-wrapper > ul > li:hover > a{
     background: darken($color-pvn, 25%);
}
.page-sidebar.mini  .page-sidebar-wrapper > ul > li >a:hover,
.page-sidebar.mini  .page-sidebar-wrapper > ul > li:hover >a {
    background: darken($color-pvn, 25%);
}
.page-sidebar.mini  .page-sidebar-wrapper > ul > li.active > a i,.page-sidebar  .page-sidebar-wrapper > ul > li:hover > a i {
    color:  $color-white  !important;
}
.page-sidebar .page-sidebar-wrapper ul > li > a > .arrow:before,
.page-sidebar  .page-sidebar-wrapper > ul > li > a > .arrow.open:before {
    color: #919091 !important;
}
.page-sidebar  .page-sidebar-wrapper ul > li.active > a .arrow:before,
.page-sidebar  .page-sidebar-wrapper > ul > li.active > a .arrow.open:before {
    color:  $color-white  !important;
}

.page-sidebar  .page-sidebar-wrapper ul > li.open > a i,
.page-sidebar  .page-sidebar-wrapper > ul > li.open > a i{
    color:  $color-white  !important;
}
.page-sidebar  .page-sidebar-wrapper > ul > li > ul.sub-menu > li.active > a,
.page-sidebar  .page-sidebar-wrapper > ul > li > ul.sub-menu > li > a:hover {
    color:  $color-white  !important;
}
.page-sidebar  .page-sidebar-wrapper > ul > li > ul.sub-menu  li > ul.sub-menu > li.active > a,
.page-sidebar  .page-sidebar-wrapper > ul > li > ul.sub-menu  li > ul.sub-menu > li > a:hover,
.page-sidebar  .page-sidebar-wrapper > ul > li > ul.sub-menu  li.open > a {
    color:  $color-white  !important;
}
.page-sidebar  .page-sidebar-wrapper > ul > li > ul.sub-menu  a .arrow:before,
.page-sidebar  .page-sidebar-wrapper > ul > li > ul.sub-menu  a .arrow.open:before {
    color: #919091  !important;
}

.page-sidebar .page-sidebar-wrapper > ul > li > ul.sub-menu > li.active > a {
font-weight: 600;
}

.header .header-quick-nav .input-append .add-on, .input-prepend .add-on {
}
.header .nav > li.dropdown,
.header .nav > li.dropdown > a {
    text-align: center;
}
.header .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color:  $color-white ;
}
.open {
    position: relative;
}
.navbar .pull-right > li.open > .dropdown-menu, .navbar .nav > li.open > .dropdown-menu.pull-right {
    margin-right:-6px;
    margin-top: 7px;
}
.pace {
    .pace-activity {
        top: 72px;
        border-top-color: $color-primary;
        border-left-color: $color-primary;
        right:15px;
        left:auto;
    }
    .pace-progress {
        background: $color-primary;
        right:auto;
        left:0;
    }
    .pace-progress-inner{
        box-shadow: 0 0 10px $color-primary, 0 0 5px $color-primary;
    }
}
.no-top {
    .pace {
        .pace-activity {
            top: 10px;
        }
    }
}
.loading-animator {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    -webkit-animation: pace-spinner 400ms linear infinite;
    -moz-animation: pace-spinner 400ms linear infinite;
    -ms-animation: pace-spinner 400ms linear infinite;
    -o-animation: pace-spinner 400ms linear infinite;
    animation: pace-spinner 400ms linear infinite;
    border-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0.4);
    border-image: none;
    border-radius: 10px 10px 10px 10px;
    border-right: 2px solid rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px;
    display: block;
    height: 14px;
    margin-left:40%;
    width: 14px;
    z-index: 2000;
}
.page-container {
    -webkit-transition: -webkit-transform 0.3s ease, left  0.3s ease, right  0.3s ease;
    transition: transform 0.3s ease, left  0.3s ease, right  0.3s ease;
    margin: 0px;
    padding: 0px;
    position: relative;
    height: 100%;
    left: 0;
    z-index: 10;
}
.chat-window-wrapper {
    font-family: $base-font-family;
    font-size: 13px;
    position: fixed;
    top: 0;
    right: 0;
    width: 260px;
    background-color: $sidebar-color;
    height: 100%;
    z-index: 5;
     -webkit-transition: opacity 0.1s ease;
    transition: opacity 0.1s ease;
    .inner-content{
        height: 100%;
        overflow: hidden;
    }
    .chat-header {
        height: 58px;
    }
    .side-widget {
        margin-top: 15px;
        display: block;
    }
    .side-widget-title {
        color: #808285;
        font-size: 10.5px;
        font-weight: 600;
        letter-spacing: 0.2px;
        padding-left:31px;
        padding-right:31px;
        text-transform: uppercase;
    }
    .side-widget-content {
        display: block;
        ul {
            &.groups {
                list-style: none;
                margin: 0;
                margin-top: 10px;
                padding-left:0;
                li {
                    line-height: 25px;
                }
                & > li {
                    & > a {
                        color:  $color-white ;
                        font-size: 13px;
                        padding: 5px 31px;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    .user-details-wrapper {
        display: block;
        margin: 8px;
        padding: 10px;
        border-radius: 3px;
        .status-icon {
            margin-right:0px;
        }
        &.active {
            background-color: #372b32;
            &:hover {
                background-color: #482f36;
                cursor: pointer;
            }
        }
        &:hover {
            background-color: $sidebar-inner-ul-list-background;
            cursor: pointer;
        }
        & > .user-profile {
            display: inline-block;
            float:left;
            border-radius: 100px 100px 100px 100px;
            height: 35px;
            overflow: hidden;
            width: 35px;
            margin-right:13px;
        }
        & > .user-details {
            display: inline-block;
            float:left;
            & > .user-name {
                display: block;
                color:  $color-white ;
            }
            & > .user-more {
                display: block;
                color: #747b89;
                font-size: 11px;
                width: 120px;
            }
        }
    }
    .chat-messages {
        position: relative;
        overflow: auto;
        height: calc('100% - 155px');
        .user-details-wrapper {
            padding: 6px;
            &:hover {
                background-color: #22262e;
                cursor: auto;
            }
        }
    }
    .user-details-status-wrapper {
        display: inline-block;
        float:left;
        min-width: 32px;
        margin-top: 8px;
    }
    .user-details-count-wrapper {
        display: inline-block;
        float:left;
        margin-top: 8px;
    }
}
.sidr {
    background-color: $sidebar-color;
    box-shadow: none;
    ul {
        &.chat-window {
            li {
                border: 0px;
                &:nth-child(odd) {
                    background-color:  $color-white ;
                }
                &:nth-child(even) {
                    background-color: #f5f6f8;
                }
            }
        }
    }
}
.sidr ul.chat-window li:hover > a, .sidr ul.chat-window li:hover > span, .sidr ul.chat-window li.active > a, .sidr ul.chat-window li.active > span, .sidr ul.chat-window li.sidr-class-active > a, .sidr ul.chat-window li.sidr-class-active > span {
    box-shadow: none;
    background-color: #fed8db;
    line-height: 16px;
}
.scrollup {
    background: url('${base-img-url}/pullup.png') no-repeat;
    background-position: 0px 0px;
    width: 27px;
    height: 27px;
    position: fixed;
    bottom: 92px;
    left:235px;
    display: none;
    text-indent: -9999px;
    z-index: 101;
    &:hover {
        background-position: -30px 0px;
    }
    &.to-edge {
        left:35px;
    }
}
.footer-widget {
    position: fixed;
    bottom: 0px;
    display: block;
    padding: 11px 21px;
    background-color: $sidebar-footer-color;
    width: 250px;
    clear: both;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    .progress {
        position: relative;
        top: 15px;
        width: 70%;
    }
    a {
        color: $sidebar-link-color;
    }
    i {
        font-size: 14px;
        color: #5e646d;
    }
    .details-status {
        background-color: #2d3139;
        border-radius: 3px 3px 3px 3px;
        color: #8B91A0;
        display: inline-block;
        font-size: 11px;
        padding: 6px;
        left:-22px;
        position: relative;
    }
}
.footer-widget a:hover,.footer-widget a:hover i {
    color: #8B91A0;
    text-decoration: none;
}
.chat-window-wrapper  input[type="text"], .chat-window-wrapper input[type="password"], .chat-window-wrapper input[type="date"], .chat-window-wrapper input[type="datetime"], .chat-window-wrapper input[type="email"], .chat-window-wrapper input[type="number"], .chat-window-wrapper input[type="search"], .chat-window-wrapper input[type="tel"], .chat-window-wrapper input[type="time"], .chat-window-wrapper input[type="url"], .chat-window-wrapper textarea, .chat-window-wrapper select {
    background-color: #0d0f12;
    height: 28px;
}
.chat-header {
    input[type="text"] {
        margin: 15px 0 15px 14px;
        width: 195px;
       border: transparent;
    }
    .iconset {
        margin: 20px 15px;
    }
}
.chat-messages-header {
    background-color: #1b1e24;
    color: #4c5264;
    padding: 8px;
    padding-left:21px;
    .status {
        height: 10px;
        width: 10px;
        border-radius: 8px;
        background-color: #e5e9ec;
        display: inline-block;
        margin-right:12px;
        &.online {
            background-color: $color-primary;
        }
        &.busy {
            background-color: #f35958;
        }
        &.away {
            background-color: $color-primary;
        }
    }
    a {
        & > i {
            color: #4c5264;
            font-size: 10px;
            float:right;
            margin-top: 5px;
            margin-right:5px;
        }
    }
}
.chat-footer {
    position: absolute;
    bottom: 0px;
    background-color: #e5e9ec;
    padding: 10px;
    padding-bottom: 0px;
    width: 92.6%;
}
.bubble {
    position: relative;
    width: 165px;
    min-height: 40px;
    padding: 0px;
    background: #e5e9ec;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #22262e;
    padding: 10px;
    cursor: pointer;
    &.old {
        background: #0d0f12;
        color: #4c5264;
        &:after {
            border-color: transparent #0d0f12;
        }
    }
    &.sender {
        background: #0ba195;
        color:  $color-white ;
        &:after {
            border-color: transparent #0ba195;
            border-width: 9px 0 9px 7px;
            right: -7px !important;
            left: auto;
        }
    }
    &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 9px 7px 9px 0;
        border-color: transparent #e5e9ec;
        display: block;
        width: 0;
        z-index: 1;
        left: -7px;
        top: 12px;
    }
}
.chat-messages {
    .sent_time {
        width: 100%;
        color: #4c5264;
        text-align: center;
        margin-top: 10px;
        font-weight: 600;
        &.off {
            display: none;
        }
    }
}
.chat-input-wrapper {
    position: absolute;
    bottom: 0;
    background-color: #1b1e24;
    width: 256px;
    padding: 7px;
    textarea {
        background-color:  $color-white ;
        padding: 0;
        margin: 0;
    }
    z-index: 110;
}

.user-chat-wrapper {
    display: block;
    padding: 10px 15px;
    .profile-wrapper {
        display: inline-block;
        float:left;
        border-radius: 100px 100px 100px 100px;
        display: inline-block;
        height: 35px;
        overflow: hidden;
        width: 35px;
        margin: 0px 10px 0 0px;
    }
    .user-chat {
        display: inline-block;
        float:left;
        .user-name {
            color: #22262e;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .user-chat-preview {
            display: block;
            float:left;
        }
        .more-details {
            display: inline-block;
            float:left;
            color: #8b8f92;
            margin-right:10px;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
.ie8 {
    .page-sidebar {
        position: absolute;
        width: 225px;
        ul {
            width: 225px;
        }
    }
    .page-content {
        margin-left:225px;
        margin-top: 0px;
    }
}
.page-content {
    margin-top: 0px;
    padding: 0px;
    background-color: $page-content-color;
    overflow: auto;
    position: relative;
    min-height: 100%;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    z-index: 6;
    &.condensed {
        margin-left:50px;
        .admin-bar-inner{
            margin-right: 50px !important;
        }
    }
    &.condensed-layout {
        margin-left:50px !important;
    }
    .admin-bar {
        -moz-box-sizing: border-box;
        bottom: -115px;
        position: fixed;
        width: 100%;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        .admin-bar-inner {
            background: none repeat scroll 0 0 padding-box $color-white;
            border-top: 1px solid rgba(0, 0, 0, 0.15);
            box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.08);
            margin-right:250px;
            padding: 30px 35px 30px 40px;
            text-align: right;
        }
    }
    .header {
        padding: 20px !important;
        h3 {
            font-size: 20px;
            display: inline;
        }
        i {
            font-size: 18px;
        }
    }
    .breadcrumb {
        font-family: $base-font-family-two;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
        box-shadow: none;
        background-color: transparent;
        padding: 0px !important;
        border: none !important;
        margin-bottom: 0px;
        a {
            margin-left:10px;
        }
        li {
            p {
                font-size: 12.5px !important;
                font-weight: 400;
                color: #7b7d82;
            }
            .active {
                font-weight: 600;
                font-size: 14px;
            }
        }
    }
    .content {
        padding-left:26px;
        padding-right:26px;
        padding-top: 83px;
    }
}
.inner-menu {
    display: inline-block;
    position: absolute;
    width: 200px;
    font-family: $base-font-family-two;
    background-color: $sidebar-color;
    height: 100%;
    .inner-wrapper {
        padding: 20px 20px 0;
        .menu-title {
            padding-left:0px;
            i {
                padding-right:0px;
            }
        }
    }
    .inner-menu-content {
        margin-top: 50px;
    }
    .menu-title {
        padding-right:23px;
    }
    ul {
        &.big-items {
            list-style: none outside none;
            margin: 0px;
            margin-bottom: 20px;
            padding: 0px;
            li {
                text-align: right;
                padding: 8px 25px;
                a {
                    color: #8b91a0;
                    font-size: 18px;
                    &:hover {
                        text-decoration: none;
                        color: #e5e9ec;
                    }
                }
                &:hover {
                    background-color: $sidebar-inner-ul-list-background;
                }
                &.active {
                    background-color: $sidebar-inner-ul-list-background;
                    a {
                        background-color: $sidebar-inner-ul-list-background;
                        color: #e5e9ec;
                    }
                }
                .badge {
                    position: relative;
                    top: -2px;
                }
            }
        }
        &.small-items {
            list-style: none outside none;
            margin: 0px;
            margin-bottom: 20px;
            padding: 0;
            li {
                text-align: right;
                padding: 8px 25px;
                a {
                    color: #8b91a0;
                    font-size: 14px;
                    &:hover {
                        text-decoration: none;
                        color: #e5e9ec;
                    }
                }
                &:hover {
                    background-color: $sidebar-inner-ul-list-background;
                }
                &.active {
                    background-color: $sidebar-inner-ul-list-background;
                    a {
                        background-color: $sidebar-inner-ul-list-background;
                        color: #e5e9ec;
                    }
                }
                .badge {
                    position: relative;
                    top: -1px;
                }
            }
        }
    }
}

.user-info-wrapper {
    display: block;
    margin: 20px 31px;
    margin-bottom: 0px;
    .profile-wrapper {
        height: 65px;
        width: 65px;
        border-radius: 100px;
        overflow: hidden;
        display: inline-block;
        margin-right:11px;
        margin-top: 9px;
        float:left;
    }
    .user-info {
        color:  $color-white ;
        display: inline-block;
        .status {
            color: #929fa5;
            font-size: 13px;
            margin-bottom: 10px;
            margin-top: 2px;
            font-weight: normal;
        }
        .profile {
            float:right;
            display: inline;
        }
        .btn-set {
            margin-top: 10px;
        }
        .username {
            font-size: 19px;
        }
        .greeting {
            font-size: 19px;
            font-weight: 600;
            position: relative;
            top: 1.5px;
        }
        a {
            color:  $color-white ;
            &:hover {
                text-decoration: none;
            }
        }
        .status-icon {
            margin: 0px 5px;
        }
        .collapse {
            width: 18px;
            height: 18px;
            background-color: #50545c;
            position: absolute;
            right: 15px;
            margin-top: 10px;
            i {
                position: relative;
                margin-top: -8px;
                top: -2px;
                left: 3px;
            }
            &:hover {
                background-color: #000000;
            }
        }
    }
}
.side-panel {
    border-width: 0;
    display: block;
    max-height: none;
    min-height: 100%;
    position: fixed;
    top: 0;
    width: 17em;
    background: linear-gradient(#F9F9F9, #EEEEEE) repeat scroll 0 0 #F9F9F9;
    border: 1px solid #AAAAAA;
    color: #333333;
    text-shadow: 0 1px 0 $color-white;
    float:right;
    right: 0;
    z-index: 1200;
}
.status-widget {
    margin-bottom: 9px;
    padding: 0 31px;
    animation-duration: 0.3s !important;
    .title {
        color:  $color-white ;
        font-size: 13px;
        i {
            float:right;
            font-size: 7px;
            margin-top: 6px;
            margin-right:6px;
            color: #8b8f92;
        }
    }
    p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 95%;
        color: #808285;
    }
    .progress {
        height: 10px;
        background-color: #2a2e36;
        background-image: -moz-linear-gradient(top, #2a2e36, #2a2e36);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#2a2e36), to(#2a2e36));
        background-image: -webkit-linear-gradient(top, #2a2e36, #2a2e36);
        background-image: -o-linear-gradient(top, #2a2e36, #2a2e36);
        background-image: linear-gradient(to bottom, #2a2e36, #2a2e36);
        background-repeat: repeat-x;
        margin-bottom: 5px;
        -webkit-transition: width 30.0s ease !important;
        -moz-transition: width 30.0s ease !important;
        -o-transition: width 30.0s ease !important;
        transition: width 30.0s ease !important;
    }
}
.status-widget-wrapper {
    font-size: 13px;
}
.status-widget .title a:hover,.status-widget .title a:active {
    text-decoration: none;
}
.status-icon {
    background: url($base-img-url+'/icon/status.png') no-repeat;
    position: relative;
    display: inline-block;
    margin-right:10px;
    top: 2px;
    &.green {
        background-position: -1px -1px;
        width: 14px;
        height: 14px;
    }
    &.red {
        background-position: -17px -1px;
        width: 14px;
        height: 14px;
    }
    &.blue {
        background-position: -33px -1px;
        width: 14px;
        height: 14px;
    }
    &.yellow {
        background-position: -48px -1px;
        width: 15px;
        height: 14px;
    }
    &.grey {
        background-position: -64px -1px;
        width: 14px;
        height: 14px;
    }
}
.user-info {
    img {
        float:left;
        margin-right:5px;
    }
    .details {
        display: inline-block;
    }
    .label {
        font-weight: 300;
        font-size: 11px;
    }
}
.mini{
    .page-sidebar-wrapper{
        overflow: visible !important;
    }
}
.container {
    height: 100%;
}
.pull-left {
float:left!important;
}

.pull-right {
float:right!important;
}
