.jvectormap-zoomin {
    bottom: 15px;
    top: auto;
}
.jvectormap-zoomout {
    bottom: 15px;
    left: 35px;
    top: auto;
}
.jvectormap-zoomin, .jvectormap-zoomout {
    background-color: #0090d9;
    padding: 8px 10px;
    height: auto;
    width: auto;
}

.craft-map-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 0;
}

.overlap-left-10 {
    position: relative;
    left: -10px;
}

.mapplic-container{
    background-color: transparent;
}

.mapplic-element.mapplic-loading{
    background-image: none;
    opacity: 0.7;
    &:after{
      content: '';
      display: block;
      position: absolute;
      z-index: 2000;
      top: 50%;
      left: 50%;
      width: 14px;
      height: 14px;
      border: solid 2px transparent;
      border-top-color: #000;
      border-left-color: #000;
      border-radius: 10px;
      -webkit-animation: pace-spinner 400ms linear infinite;
      -moz-animation: pace-spinner 400ms linear infinite;
      -ms-animation: pace-spinner 400ms linear infinite;
      -o-animation: pace-spinner 400ms linear infinite;
      animation: pace-spinner 400ms linear infinite;   
    }
}