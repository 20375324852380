// Webarch Default Theme
// --------------------------------------------------
// Copyright Reserved Revox - 2014
// --------------------------------------------------

$base-img-url: "../../img";
//Font
$base-font-family: 'Arial';
$base-font-family-two: 'Open Sans';
$base-font-color:#6f7b8a;
$base-font-size:13px;


// Global Color
// --------------------------------------------------
// Base Grey Guided Color * change only $base-light-default
// --------------------------------------------------
$base-light-default:#c1cace;
$base-light-two:mix($base-light-default, #fff, 78%);
$base-light-three:mix($base-light-default, #fff, 50%); //e0e5e7
$base-light-four:mix($base-light-default, #fff, 35%); //e9ecee
$base-light-five:mix($base-light-default, #fff, 15%); //f6f7f8


// Boostrap Color Options
// --------------------------------------------------
$color-primary-grey:#d1dade;
$color-primary-black-overlay:#6f6f6f; //#000 with 53% Opacity

$color-white: #ffffff;
$color-dark : #1B1E24;
$color-green: #FAC42B;
$color-green-dark: $color-green;
$color-blue: #0090d9;
$color-yellow: #fbb05e;
$color-red: #f35958;
$color-grey: #8b91a0;
$color-black: #1b1e24;
$color-purple: #735f87;

$color-primary:#FAC42B;
$color-primary-dark:#438a85;
$color-primary-light:#f3faf8;

$color-success:#0090d9;
$color-success-dark:#246a8e;
$color-success-light:#ebf6fb;
$color-succss-inverse:#1f3853;

$color-danger:#f35958;
$color-danger-dark:#e76e70;
$color-danger-light:#fff6f4;
$color-important: $color-danger;

$color-warning:#fbb05e;
$color-warning-dark:#ce8f22;
$color-warning-light:#fffcee;
$color-warning-luminous:#fdd01c;

$color-info:#1f3853;
$color-info-dark:'';
$color-info-light:'';


// Layout
// --------------------------------------------------
// Sidebar Colors Vars
// --------------------------------------------------
//Sidebar top header Background
$sidebar-header-color:#22262E;
//Sidebar top header icon background
$sidebar-header-iconset-background:#1B1E24;
$sidebar-header-iconset-background-active:#0D0F12;
//Sidebar top icon image path with Retina Option
$sidebar-header-iconset:url($base-img-url+"/icon/top-tray.png") no-repeat;
$sidebar-header-iconset-2x:url($base-img-url+'/icon/top-trayx2.png') no-repeat;
//Sidebar background Color
$sidebar-color:#1B1E24;
//Sidebar Link Font Color
$sidebar-link-color:#8B91A0;
//Sidebar Link Hight light color
$sidebar-link-hover:$sidebar-color;
//Sidebar Secodary menu background
$sidebar-inner-ul-list-background:#22262E;
//Sidebar Secodary menu link hover color
$sidebar-inner-link-hover:$sidebar-color;
//Sidebar Footer Background
$sidebar-footer-color:#22262E;
//Sidebar Size Options
$sidebar-width-width:250px;
$sidebar-condensed-width:50px;

//Form Elements
$input-active-background-color:#f4f5f7;
$input-active-bordrer-color:#f1f1f1;

//Page Global
$page-header-color:#fff;
$page-content-color:#e5e9ec;
$default-border-color:#e5e9ec;


//Form Elements
$input-active-background-color:#f4f5f7;
$input-active-bordrer-color:#f1f1f1;


//Typo Vars
$heading-font-color-light:#505458;
$heading-font-color-dark:#282323;

//Table
$table-body-background:#ecf0f2;
$table-hover-background:#e2e8eb;
$table-border:#E8EDF1;
