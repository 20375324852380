#wrap {
    min-height: 100%;
    height: auto;
    margin: 0 auto -60px;
    padding: 0 0 60px;
}
.error-body {
    background-color: #e5e9ec !important;
    font-family: $base-font-family-two;
    height: 100%;
}
.error-wrapper {
    min-height: 100%;
    height: auto;
    margin: 0 auto -130px;
    padding: 0 0 60px;
    height: 624px;
    &:after {
        content: "";
        display: block;
    }
}
.wrappers{
    display: inline-flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    vertical-align: text-top;
    width: inherit;
    height: inherit;
}
.error-container {
    font-family: $base-font-family-two;

    .error-number {
        color: #22262e;
        font-size: 120px;
        text-align: center;
        font-weight: 600;
        line-height: 130px;
    }
    .error-description {
        color: #22262e;
        font-size: 32px;
        text-align: center;
        font-weight: 300;
        line-height: 34px;
    }
    .error-description-mini {
        color: #22262e;
        font-size: 18px;
        text-align: center;
        font-weight: 300;
    }
    .input-prepend {
        &.inside {
            .add-on {
                background-color:  $color-white ;
                padding-top: 6px;
                color: #a1a1a1;
            }
        }
    }
    ul {
        &.footer-links {
            margin: 0;
            padding: 0;
            list-style-type: none;
            text-align: center;
            li {
                display: inline;
                border-right: 1px solid #000;
                padding: 0px 10px;
                a {
                    color: #22262e;
                    font-size: 12px;
                }
                &:last-child {
                    display: inline;
                    border-right: none;
                    padding: 0px 10px;
                }
            }
            &.small-links {
                li {
                    border-right: 1px solid #616a7b;
                    a {
                        color: #616a7b;
                        font-size: 11px;
                    }
                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }
    }
    .copyright {
        font-size: 12px;
        text-align: center;
    }
}
.error-main {
    text-align: center;
    position: absolute;
    margin-top: 18%;
}
#footer {
    height: 130px;
}
#gmaps_context_menu {
    text-align: center;
    a {
        color: #8b91a0;
        &:hover {
            color: #1b1e24;
            text-decoration: none;
        }
    }
}
