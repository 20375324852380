$color-pvn: #FAC42B;
// Fonts
@import url(//fonts.googleapis.com/css?family=Open+Sans:400,300,600,700);

// Mixins tools
@import "mixins";

@import "themes/default/theme";

@import "responsive";
// Includes of Modules
@import "modules";

body, html {
  color: #505458;
  font-family: "Open Sans"
}

.no-padding-right {
  padding-left:15px;
  padding-right: 0;
}

.no-padding-left {
  padding-left: 0;
  padding-right: 15px;
}
body {
  letter-spacing: .010em;
  line-height: 1.6em;
}
.text-pvn {
  color: $color-pvn !important;
}

.background-pvn {
  background-color: $color-pvn !important;
}

.categoryIcon, .icon-onderwerp {
  height: 20px;
}
.block {
  display: block;
}
.alertbox {
    margin-bottom: 30px;
    min-height: 470px !important;
    max-height: 470px !important;
    .alert-description {
      max-width: 470px;
    }

    .alert-view {
      max-height: 80px;
      min-height: 80px;
      overflow-y: scroll;
    }

    .alert-body {
        margin-right: -15px;
        margin-left: -15px;
        padding-left: -10px;
        min-height: 245px;
    }

}

.userbox {
  max-height: 565px;
}

.verified {
  max-width: 20px;
  max-height: 20px;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 5px;
}

md-toast {
  z-index: 9999;
  position: fixed !important;
}
._md-center {
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.btn-warning {
  background-color: #eb9316;
  &:hover {
    background-color:#b59067;
  }
}

.page-sidebar.mini .time {
    display: none;
}

.reactie {
  height: 75px;
  .user-profile-pic img {
      border-radius: 50%;
      display: inline-block;
      height: 40px;
      overflow: hidden;
      width: 40px;
      border: 5px solid #ffffff;
      background-color: #e5e9ec;
  }
}

.chart > svg > text, .chart > svg > text > tspan {
  color: #1b1e24 !important;
  font-family: "Open Sans";
}

.no-padding-height {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}


.no-border {
  border:none !important;
}

.form-date {
  display: inline-flex;

  li {
    margin-right:10px;margin-top:-5px;
  }
  input {
    height: 100% !important;
  }
}

.flexitem {
  width: 20%;
  float: left;
}

.categorie {
  min-height: 161px;
}

.zone {
  min-height: 140px;
  border: 1px solid #EAEAEA;
  .tiles .tiles-title {
    min-height: 50px;
  }
}

.chart {
  height: 250px;
  cursor: pointer;
}
.usercard {
  height: 300px;
  width: 300px;
}

.icon-onderwerp {
	width: 25px;
}

.alertbox {
	min-height: 450px;
	max-height: 450px;
	margin-bottom: 30px;
}

.alerttitle {
	overflow:hidden;
}

.clickable {
	cursor:pointer;
}

.tagcontainer {
	margin: 0;
	position: relative;
	display: inline-block;
	width:100%;
	border: 1px solid #ccc;
	zoom: 1;
	vertical-align: middle;
}
.tagcontainer li {
	float: left;
	list-style: none;
}
.tagcontainer .tagbox {
	height: auto !important;
	height: 1%;
	margin: 0;
	padding: 0;
	position: relative;

	cursor: text;
	overflow: hidden;
	background-color: #fff;
	background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(1%, #eee), color-stop(15%, #fff));
	background-image: -webkit-linear-gradient(top, #eee 1%, #fff 15%);
	background-image: -moz-linear-gradient(top, #eee 1%, #fff 15%);
	background-image: linear-gradient(top, #eee 1%, #fff 15%);
}

.alert-body {
	margin-right: -15px;
	margin-left: -15px;
	padding-left:-10px;
	min-height: 245px;
}

.alert-description {
	width:100%;
}

.zindex {
  z-index: 999;
}

.m-h-40 {
	margin-top:40px;
	margin-bottom:40px;
}

.tag {
  background-color: #FAC42B;
  background-image: none;
  border: none;
  box-shadow: none;
  color: inherit;
  border-radius: 8px;
  margin: 8px 2px 7px 10px;
  padding: 4px 9px 4px 21px;
}

.alertmap {
  width: 100% !important;
  height: 400px !important;
}

.button-div {
	position:absolute;
	bottom:5px;
	width: 100%;
}

.button-div-top {
	position:absolute;
	top:5px;
	width: 100%;
}

.right-button {
	right:0px;
}

.border-top {
  border-top: 1px solid #cecece !important;
}
