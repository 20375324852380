.cbp_tmtimeline {
    margin: 30px 0 0 0;
    padding: 0;
    list-style: none;
    position: relative;
    li {
        position: relative;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 10px;
        background: #f2f4f6;
        left: 20%;
        margin-left: -10px;
    }
    & > li {
        .cbp_tmtime {
            display: block;
            width: 25%;
            padding-right: 100px;
            position: absolute;
            font-family: $base-font-family;
            text-align: right;
            .date {
                font-size: 1.2em;
                color: #505458;
                margin-bottom: 5px;
                display: block;
            }
            .time {
                font-size: 1.5em;
                color: #505458;
                display: block;
            }
        }
        &:nth-child(odd) {
            .cbp_tmtime {
                span {
                    &:last-child {
                        color: #505458;
                    }
                }
            }
            .cbp_tmlabel {
                background: $color-white;
                &:after {
                    border-right-color: $color-white;
                }
            }
        }
        .cbp_tmlabel {
            margin: 0 0 15px 25%;
            background: $color-white;
            font-size: 13px;
            line-height: 1.4;
            position: relative;
            h2 {
                margin-top: 0px;
                padding: 0 0 10px 0;
                border-bottom: 1px solid rgba(255,255,255,0.4);
            }
            &:after {
                right: 100%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                border-right-color: $color-white;
                border-width: 10px;
                top: 10px;
            }
        }
        .cbp_tmicon {
            width: 40px;
            height: 40px;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 37px;
            -webkit-font-smoothing: antialiased;
            position: absolute;
            color: #7c8694;
            background: $color-white;
            border-radius: 50%;
            box-shadow: 0 0 0 8px $color-white;
            text-align: center;
            left: 20%;
            top: 0;
            margin: 0 0 0 -25px;
            font-size: 1.3em;
            &.success {
                background: $color-success;
                box-shadow: 0 0 0 8px #2ba3df;
                color:  $color-white ;
            }
            &.primary {
                background: $color-primary;
                box-shadow: 0 0 0 8px #33b5aa;
                color:  $color-white ;
            }
            &.warning {
                background: $color-warning;
                box-shadow: 0 0 0 8px #fcbd79;
                color:  $color-white ;
            }
            &.danger {
                background: $color-danger;
                box-shadow: 0 0 0 8px #455a70;
                color:  $color-white ;
            }
            &.info {
                background: $color-info;
                box-shadow: 0 0 0 8px #455a70;
                color:  $color-white ;
            }
        }
    }
    .action-links {
        margin: 4px 0 0;
        list-style: none;
        font-size: 13px;
        padding: 0;
        float:left;
        li {
            display: inline;
            float: left;
            position: relative;
            display: inline;
            float: left;
            position: relative;
            padding-left: 0px;
            padding-right: 8px;
            color: #8b91a0;
            &:after {
                color: #999999;
                content: ".";
                padding-left: 8px;
            }
            &:last-child {
                &:after {
                    content: "";
                }
            }
        }
    }
}
/* Media Queries */
@media screen and (max-width: 65.375em) {

    .cbp_tmtimeline > li .cbp_tmtime {
        
    }
}

@media screen and (max-width: 47.2em) {
    .cbp_tmtimeline:before {
        display: none;
    }

    .cbp_tmtimeline > li .cbp_tmtime {
        width: 100%;
        position: relative;
        padding: 0 0 20px 0;
    }

    .cbp_tmtimeline > li .cbp_tmtime  {
        text-align: left;
    }

    .cbp_tmtimeline > li .cbp_tmlabel {
        margin: 0 0 30px 0;
        padding: 1em;
        font-weight: 400;
        font-size: 95%;
    }

    .cbp_tmtimeline > li .cbp_tmlabel:after {
        right: auto;
        left: 20px;
        border-right-color: transparent;
        border-bottom-color: #fff;
        top: -20px;
    }

    .cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
        border-right-color: transparent;
        border-bottom-color: #fff;
    }

    .cbp_tmtimeline > li .cbp_tmicon {
        position: relative;
        float: right;
        left: auto;
        margin: -55px 5px 0 0px;
    }   
}